import { proxy } from 'valtio';

export const inquiryModal = proxy({
  isOpen: false,
  initialInquiry: '',
});

export const onClose = () => {
  inquiryModal.isOpen = false;
  inquiryModal.initialInquiry = '';
};

export const onOpenInquiry = (initialInquiry?: string) => {
  inquiryModal.isOpen = true;
  inquiryModal.initialInquiry = initialInquiry ?? '';
};
