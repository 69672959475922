import type { SpriteProps } from './types';
import spriteSrc from './sprites.svg';

export default function Sprite({ name, width, height, ...svgProps }: SpriteProps) {
  return (
    <svg
      {...svgProps}
      width={width}
      height={height}
      viewBox={[0, 0, width, height].join(' ')}
    >
      <use href={`${spriteSrc}#${name}`} />
    </svg>
  );
}
